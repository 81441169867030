.create-product-container {
    height: 350px;
    width: 300px;
    border: 1px solid #FFA303;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}
.create-product-container2 {
    height: auto;
    width: 300px;
    border: 1px solid #FFA303;
    border-radius: 5px;
    overflow: hidden;
}

.top-item {
    height: 90%;
    background-color: #F6EEFF;
    width: 100%;
}

.bottom-item {
    height: 10%;
    background-color: #521986;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    padding: 6px 0;
    cursor: pointer;
}

.top-item-img {
    border-bottom: 1px solid #FFA303;
    width: 100%;
}


.live-dot-text {
    width: 5px;
    height: 5px;
    background-color: greenyellow;
    border-radius: 50%;
}

.text-bold {
    font-weight: bold;
    color: #521986;
}


.border-fileInput {
  border: 1px solid #FFA303;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 0 10px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.btn {
  border: 2px solid var(--lightgray);
  background-color: transparent;
  border-radius: 10px;
}
.btn-2 {
    padding-left: 5px;
  border: 1px solid var(--lightgray);
  color: var(--gray);
  background-color: transparent;
  height: 38px;
  width: 100%;
  /* border-right: none; */
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.practice-img {
    width: 280px;
    height: 200px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
}

.practice-card-container {
    width: 300px;
    border-radius: 5px;
    border: 1px solid #FFA303;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.practice-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.practice-card-container:hover {
    box-shadow: 0 0 4px 2px rgba(244, 153, 17, 0.33);
    transition: 0.2s;
}