.heading {
    white-space: nowrap;
    margin-right: 20px;
}

.heading1 {
    font-weight: bold;
}

.dropdown-toggle::after {
    display: none;
    visibility: hidden;
}