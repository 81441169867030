.imgContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.imgContainer img {
  width: 100%;
  height: 100%;
}

.profileCOntainer {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}

.profileImgContainer {
  width: 90px;
  height: 90px;
}

.profileImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.profile-text {
  color: white;
}

.box-container {
  border: 1px solid #ffa303;
  padding: 10px 20px;
  border-radius: 5px;
  width: 180px;
  color: #521986;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.box-container-active {
  background-color: #521986;
  color: white;
  border: 1px solid #521986;
}

.boxContainer {
  border: 1px solid #ffa303;
  border-radius: 5px;
}

.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

.profile-pic > input {
  display: none;
}
.profile-pic > img {
  position: absolute;
  object-fit: cover;
  width: 120px;
  height: 120px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

.profile-pic > .-label {
  cursor: pointer;
  height: 120px;
  width: 120px;
}

.-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  color: rgb(250, 250, 250);
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}

.profile-pic > span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}

.profile-text-container > h5 {
  font-weight: bold;
  color: #521986;
}

.profile-text-container > span {
  font-size: 18px;
  color: #521986;
}

.input-container {
  width: 49%;
  margin-bottom: 30px;
}

.input-container > span {
  font-size: 18px;
}
.input-container > input {
  height: 40px;
  border: 1px solid #ffa303;
  border-radius: 5px;
  outline: none;
}
.input-container > select {
  height: 40px;
  border: 1px solid #ffa303;
  border-radius: 5px;
  outline: none;
}

.btn-form-submit {
  width: 150px;
  height: 40px;
  background-color: #521986;
  color: white;
  border-radius: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #521986;
}

input:focus + .slider {
  box-shadow: 0 0 1px #521986;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.bolder-font {
    font-weight: bold;
}
