@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.rounded-t-5 {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

@media (max-width: 550px) {
  .rounded-tr-lg-0 {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0rem;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #521986;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.pro-sidebar .pro-menu {
  padding: 0 !important;
}

.pro-sidebar, .pro-sidebar-inner {
  background-color: #521986 !important;
  background: #521986 !important;
  color: #521986 !important;
}
.blue-text {
  color: #521986;
  font-weight: bold;
}

.sc-beqWaB {
  width: 100% !important;
  height: 200px !important;
  border: 2px dashed gray !important;
    display: flex !important;
  flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
}

.sc-beqWaB .ewqTBN {
  width: 100% !important;
}
.sc-beqWaB > svg {
  margin-top: 50px;
}
.sc-dmqHEX {
  display: block !important;
  height: 40px !important;

}

.sc-hLseeU  {
  font-size: 20px !important;
}

.file-types {
  display: block !important;
  margin: 0 auto;
}