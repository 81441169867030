.search-Input {
    width: 89%;
    position: relative;
}

.search-Input input {
    width: 100%;
    border: 1px solid #FFA303;
    outline: none;
    border-radius: 5px;
    height: 40px;
    color: #521986;
    font-size: 14px;
    padding: 0 10px;
}
.search-Input input::placeholder {
    color: #521986;
    font-size: 18px;
}

.search-Input .search-icon {
    position: absolute;
    right: 0;
    top: 50%;
        color: #521986;
        margin-right: 10px;
    transform: translateY(-50%);
}

.live-item {
    width: 9%;
        height: 40px;
            border: 1px solid #FFA303;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
}

.month-selector {
    border: 1px solid #FFA303;
    height: 40px;
    width: 80px;
    border-radius: 5px;
    outline: none;
}

.download-btn {
    height: 40px;
    border: 1px solid #FFA303;
    background-color: white;
    border-radius: 5px;
    color: #FFA303;
}
.pie-container {
    width: 60%;
    height: 100%;
}

.product-pie {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 16px;
}

.product-dot {
    width: 5px;
    height: 5px;
    background-color: #521986;
    border-radius: 50%;
}

.live-dot {
        width: 5px;
    height: 5px;
    background-color: greenyellow;
    border-radius: 50%;
    margin-right: 10px;
}

.view-all-btn {
    border: none;
    outline: none;
    background-color: white;
    color: #FFA303;
}

.spinner {
    border: 8px solid #521986;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;


}

.spinner > span {
    font-size: 28px;
    font-weight: bold;
    color: #521986;
}

.tableHeaders > span {
    font-weight: bold;
    width: 20%;
    text-align: left;
        padding-left: 10px;
}

.tableRows {
    border: 1px solid #FFA303;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    margin: 10px 0;

}

.tableRows > span {
    width: 20%;
    text-align: left;
        padding-left: 10px;
}
