.box-item {
    border: 1px solid #FFA303;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    width: 300px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #521986;
            font-weight: bold;
            cursor: pointer;
}
.box-item2 {
    border: 1px solid #FFA303;
        border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
        width: 300px;
            padding: 10px 0;
                display: flex;
    align-items: center;
    justify-content: center;
        color: #521986;
        font-weight: bold;
        cursor: pointer;
}

.box-item-active {
    background-color: #521986;
    border: 1px solid #521986;
    color: white;
}

.color-selector-resume > h4 {
    font-weight: bold;
    color: #521986;
}
.color-selector-item > h6 {
    color: #521986;
    font-size: 18px;
    margin: 0;
    margin-right: 20px;
}

.color-selector-item > .color {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #521986;
    margin-right: 10px;
    cursor: pointer;
}

.color-selector-item > .color1 {
    background-color: #FFA303;
}
.color-selector-item > .color2 {
    background-color: blueviolet;
}
.color-selector-item > .color3 {
    background-color: green;
}
.color-selector-item > .color4 {
    background-color: darkgrey;
}
.color-selector-item > .color5 {
    background-color: darkred;
}

.modal-color-selector > .color {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #521986;
    margin-right: 10px;
    cursor: pointer;
}

.modal-color-selector > .color1 {
    background-color: #FFA303;
}
.modal-color-selector > .color2 {
    background-color: blueviolet;
}
.modal-color-selector > .color3 {
    background-color: green;
}
.modal-color-selector > .color4 {
    background-color: darkgrey;
}
.modal-color-selector > .color5 {
    background-color: darkred;
}

.resume-container {
    height: auto;
    width: 300px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid gray;
    cursor: pointer;
}

.resume-image-container {
    width: 100%;
    height: 350px;
    border-bottom: 1px solid gray;

}

.resume-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.resume-span1{
    font-weight: bold;
}
.resume-span2{
    background-color: #FFA303;
    padding: 5px 10px;
    border-radius: 18px;
}

.discard-btn {
    border: 2px solid #521986;
    outline: none;
    width: 150px;
    height: 40px;
    background-color: white;
    color: #521986;
    border-radius: 5px;
}
.upload-btn {
    border: 2px solid #521986;
    outline: none;
    width: 150px;
    height: 40px;
    background-color: #521986;
    color: white;
    border-radius: 5px;
}

.modal-font {
    font-size: 18px;
}

.modal-input {
    height: 38px;
    border: 1px solid #FFA303;
    border-radius: 5px;
    outline: none;
    padding: 0 10px;
}


.checkbox[type="checkbox"] {
    background-color: #FFA303;
}
.checkbox[type="checkbox"]:checked {
    background-color: #FFA303 !important;
    border-color: #FFA303 !important;
}